import {
  useAddBreederUploadedImageEndpoint,
  useAddDogUploadedImageEndpoint,
  useAddLitterPuppyImageEndpoint,
  useAddLitterUploadedImageEndpoint,
  useAddMemberUploadedImageEndpoint,
  useCreateMemberUploadAssetTokenEndpoint,
} from "./generated/happydogsComponents";

export const useUploadMemberImage = () => {
  const createUploadAssetTokenMutation = useCreateMemberUploadAssetTokenEndpoint();
  const addUploadedImageMutation = useAddMemberUploadedImageEndpoint();

  return async (memberId: string, file: File | Blob) => {
    const data = await createUploadAssetTokenMutation.mutateAsync({
      body: {
        memberId,
        mimeType: file.type,
      },
    });
    await fetch(data.url, {
      method: "PUT",
      body: file,
      headers: data.headers,
    });

    await addUploadedImageMutation.mutateAsync({
      body: {
        mimeType: file.type,
        assetId: data.assetId,
        memberId: memberId,
        filename: "",
        setProfilePicture: true,
      },
    });

    return { url: data.url, assetId: data.assetId };
  };
};

export const useUploadDogimage = () => {
  const createUploadAssetTokenMutation = useCreateMemberUploadAssetTokenEndpoint();
  const addUploadedImageMutation = useAddDogUploadedImageEndpoint();

  return async (memberId: string, dogId: string, file: File | Blob, profilePicture: boolean) => {
    const data = await createUploadAssetTokenMutation.mutateAsync({
      body: {
        memberId,
        mimeType: file.type,
      },
    });
    await fetch(data.url, {
      method: "PUT",
      body: file,
      headers: data.headers,
    });

    await addUploadedImageMutation.mutateAsync({
      body: {
        mimeType: file.type,
        assetId: data.assetId,
        setProfilePicture: profilePicture,
        memberId,
        dogId: dogId,
        filename: "",
      },
    });

    return { url: data.url, assetId: data.assetId };
  };
};

export const useUploadBreederImage = () => {
  const createUploadAssetTokenMutation = useCreateMemberUploadAssetTokenEndpoint();
  const addUploadedImageMutation = useAddBreederUploadedImageEndpoint();

  return async (memberId: string, breederId: string, file: File | Blob, profilePicture: boolean) => {
    const data = await createUploadAssetTokenMutation.mutateAsync({
      body: {
        memberId,
        mimeType: file.type,
      },
    });
    await fetch(data.url, {
      method: "PUT",
      body: file,
      headers: data.headers,
    });

    await addUploadedImageMutation.mutateAsync({
      body: {
        breederId: breederId,
        mimeType: file.type,
        assetId: data.assetId,
        setProfilePicture: profilePicture,
        memberId,
        filename: "",
      },
    });

    return { url: data.url, assetId: data.assetId };
  };
};

export const useUploadLitterImage = () => {
  const createUploadAssetTokenMutation = useCreateMemberUploadAssetTokenEndpoint();
  const addUploadedImageMutation = useAddLitterUploadedImageEndpoint();

  return async (memberId: string, litterId: string, file: File | Blob, profilePicture: boolean) => {
    const data = await createUploadAssetTokenMutation.mutateAsync({
      body: {
        memberId,
        mimeType: file.type,
      },
    });
    await fetch(data.url, {
      method: "PUT",
      body: file,
      headers: data.headers,
    });

    await addUploadedImageMutation.mutateAsync({
      body: {
        litterId: litterId,
        mimeType: file.type,
        assetId: data.assetId,
        memberId: memberId,
        filename: "",
        setPedigree: false,
        setProfilePicture: profilePicture,
      },
    });

    return { url: data.url, assetId: data.assetId };
  };
};

export const useUploadPuppyImage = () => {
  const createUploadAssetTokenMutation = useCreateMemberUploadAssetTokenEndpoint();
  const addUploadedImageMutation = useAddLitterPuppyImageEndpoint();

  return async (memberId: string, litterId: string, puppyId: string, file: File | Blob) => {
    const data = await createUploadAssetTokenMutation.mutateAsync({
      body: {
        memberId,
        mimeType: file.type,
      },
    });
    await fetch(data.url, {
      method: "PUT",
      body: file,
      headers: data.headers,
    });

    await addUploadedImageMutation.mutateAsync({
      body: {
        litterId: litterId,
        mimeType: file.type,
        assetId: data.assetId,
        memberId: memberId,
        filename: "",
        puppyId: puppyId,
        setProfilePicture: true,
      },
    });

    return { url: data.url, assetId: data.assetId };
  };
};
