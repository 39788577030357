"use client";

import { useAuth, useUser } from "@clerk/nextjs";
import { Container } from "@mui/material";
import { useRouter } from "next/navigation";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import {
  fetchChangeLitterAvailabilityEndpoint,
  fetchRegisterLitterEndpoint,
  useGetPrivateMemberBreederEndpoint,
} from "../../generated/happydogsComponents";
import { RegisterLitterEndpointCommand } from "../../generated/happydogsSchemas";
import AddLitterRegistrationForm, { RegisterLitterFn } from "../../MyProfile/Modals/AddLitter/LitterInfoForm";
import { HandleMultiplePromisesWithToast } from "../../Toasts";
import { useUploadLitterImage } from "../../use-upload-image";
import CustomDrawer from "../CustomDrawer";
import AddDog from "./AddDog";

const AddLitter = ({ setOpen }: { setOpen: Dispatch<SetStateAction<boolean>> }) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [litterSlug, setLitterSlug] = useState<string>("");
  const [newLitter, setNewLitter] = useState<Partial<RegisterLitterEndpointCommand>>({});
  const router = useRouter();
  const [profilePicture, setProfileImage] = useState<File>();
  const { user } = useUser();
  const memberId = user?.publicMetadata?.member_id as string;
  const authentication = useAuth();
  const uploadImageFn = useUploadLitterImage();

  const { data: breeder, isLoading: isLoadingBreeder } = useGetPrivateMemberBreederEndpoint(
    { pathParams: { memberId: memberId! } },
    { enabled: !!memberId! },
  );

  //DRAFT CODE FOR LATER
  /*
  const steps = [
    {
      label: "Informasjon",
      Component: (
        <AddLitterRegistrationFOrm
          newLitter={newLitter}
          setNewLitter={setNewLitter}
          registerLitter={function (): Promise<void | null> {
            throw new Error("Function not implemented.");
          }}
        />
      ),
    },
    { label: "Om Kullet", Component: <AboutLitter newLitter={newLitter} setNewLitter={setNewLitter} /> },
    { label: "Valper", Component: <AddPuppies puppies={puppies} setPuppies={setPuppies} /> },
    { label: "Galleri", Component: <GalleryForm state={[galleryImages, setGalleryImages]} /> },
  ];*/

  const registerLitter: RegisterLitterFn = HandleMultiplePromisesWithToast(
    function* (command, memberId, breederId, location) {
      yield fetchRegisterLitterEndpoint({
        body: {
          ...command,
          memberId: memberId,
          breederId: breederId,
          puppies: [],
          location,
        },
      }).then(async ({ litterId, litterSlug }) => {
        await fetchChangeLitterAvailabilityEndpoint({
          body: {
            litterId,
            available: command.available ?? false,
          },
        });

        if (profilePicture) {
          await uploadImageFn(memberId, litterId, profilePicture, true);
        }
        router.push(`/me/litters/${litterSlug}`);
      });
    },
    () => {
      setOpen(false);
      setSuccess(true);
    },
  );

  const [showAddDog, setShowAddDog] = useState(false);

  const formAction = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.currentTarget.reportValidity();
    const { location } = e.currentTarget;
    //@ts-expect-error TODO: Validate newLitter
    registerLitter(newLitter, memberId, breeder!.id, location.value);
  };

  return (
    <>
      <Container maxWidth="lg" component="form" onSubmit={formAction}>
        <AddLitterRegistrationForm
          profileImage={profilePicture!}
          setProfileImage={setProfileImage}
          newLitter={newLitter}
          setNewLitter={setNewLitter}
          memberId={memberId}
          setOpen={setOpen}
          setShowAddDog={setShowAddDog}
        />
      </Container>{" "}
      <CustomDrawer open={showAddDog} setOpen={setShowAddDog}>
        <AddDog setOpen={setShowAddDog} redirect={false} />
      </CustomDrawer>
    </>
  );
};

export default AddLitter;
