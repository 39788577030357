import { useEffect, useState } from "react";

export const useObjectUrl = (file: File | undefined) => {
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    if (file) {
      setSrc(URL.createObjectURL(file));
    }

    return () => {
      if (src) {
        URL.revokeObjectURL(src);
      }
    };
  }, [file]);

  return src;
};
