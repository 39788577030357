import { InputLabel } from "@mui/material";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, { ToggleButtonGroupProps } from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { orange, tan } from "./Theme";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-root": {
    border: 0,
    padding: 0,
    textTransform: "none",
    "&:hover": {
      backgroundColor: `${orange[70]}`,
    },
  },
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    width: "50%",
    height: "41px",
    minWidth: "fit-content",
    textTransform: "none",

    "&:not(:first-of-type)": {
      borderRadius: "10px",
      textTransform: "none",
    },
    "&:first-of-type": {
      borderRadius: "10px",
      textTransform: "none",
    },
    "&.Mui-selected": {
      backgroundColor: `${orange[70]}`,
      textTransform: "none",
      "&:hover": {
        backgroundColor: `${orange[70]}`,
      },
    },
  },
}));

interface ToggleProps extends ToggleButtonGroupProps {
  leftValue: string;
  rightValue: string;
  centerValue?: string;
  setSelectedResult: Dispatch<SetStateAction<string>>;
  label?: string;
  style?: React.CSSProperties;
}

export default function CustomToggleButton({ leftValue, rightValue, centerValue, setSelectedResult, label, style, ...props }: ToggleProps) {
  return (
    <div>
      {label && <InputLabel sx={{ color: tan[300], textAlign: "left" }}>{label}</InputLabel>}
      <Paper
        elevation={0}
        sx={{
          border: `1px solid ${tan[50]}`,
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <StyledToggleButtonGroup
          {...props}
          size="medium"
          defaultValue={rightValue}
          exclusive
          onChange={(_, value) => setSelectedResult(value)}
          style={style}
        >
          <ToggleButton value={leftValue} aria-label={leftValue}>
            {leftValue}
          </ToggleButton>
          {centerValue && (
            <ToggleButton value={centerValue} aria-label={centerValue}>
              {centerValue}
            </ToggleButton>
          )}
          <ToggleButton value={rightValue} aria-label={rightValue}>
            {rightValue}
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
}
