import { Autocomplete, Box, Button, ButtonBase, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRouter } from "next/navigation";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { CiImageOn } from "react-icons/ci";
import { ColumnWrapper } from "../../../../styles/styledComponents";
import CustomToggleButton from "../../../CustomToggleButton";
import Uploader from "../../../ImageUpload";
import KommuneSelect from "../../../KommuneSelect";
import { tan } from "../../../Theme";
import dogbreeds from "../../../dogbreeds.json" assert { type: "json" };
import { useGetPrivateMemberDetailsEndpoint } from "../../../generated/happydogsComponents";
import { RegisterDogEndpointCommand } from "../../../generated/happydogsSchemas";
import { useObjectUrl } from "../../../handleObject";

interface AddDogProps {
  newDog: Partial<RegisterDogEndpointCommand>;
  setNewDog: Dispatch<SetStateAction<Partial<RegisterDogEndpointCommand>>>;
  registerDog: (location: string) => void;
  setProfileImage: (file: File) => unknown;
  profilePicture: File;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const AddDogRegistrationForm = ({ newDog, setNewDog, registerDog, setProfileImage, profilePicture, setOpen }: AddDogProps) => {
  const { data: member } = useGetPrivateMemberDetailsEndpoint({});
  const [uploadImage, setUploadImage] = useState(false);

  const handleChange = <T extends keyof RegisterDogEndpointCommand>(key: T, value: RegisterDogEndpointCommand[T]) => {
    setNewDog((prevDog) => ({
      ...prevDog,
      [key]: value,
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.currentTarget.reportValidity();
    const { location } = e.currentTarget;
    registerDog(location.value);
  };

  const src = useObjectUrl(profilePicture);
  const router = useRouter();

  return (
    <Box display={"flex"} flexDirection={"column"} gap={{ md: "40px", xs: "20px" }} paddingBottom="15px">
      <Typography variant="h2">Legg til din hund</Typography>
      <Box component={"form"} onSubmit={handleSubmit} id="addDog">
        <Box sx={{ display: "flex", gap: { md: "40px", xs: "20px" } }}>
          <ColumnWrapper gap={"20px"}>
            <TextField
              label="Registreringsnavn"
              value={newDog.registryName ?? ""}
              onChange={(e) => handleChange("registryName", e.target.value)}
              required
              placeholder="eks. HappyDogs Bella"
              onInput={(e: FormEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;

                const reg = new RegExp(/\w{3,}.*/);

                if (reg.test(target.value)) {
                  target.setCustomValidity("");
                } else {
                  target.setCustomValidity("Navnet må inneholde minst 3 bokstaver");
                }
              }}
            />
            <TextField value={newDog.titles ?? ""} onChange={(e) => handleChange("titles", e.target.value)} label="Titler" />
            <TextField
              label="Registreringsnummer"
              value={newDog.registryId ?? ""}
              onChange={(e) => handleChange("registryId", e.target.value)}
              required
              placeholder="NO44444/24"
              onInput={(e: FormEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;

                const reg = new RegExp(/^\S*$/);

                if (reg.test(target.value)) {
                  target.setCustomValidity("");
                } else {
                  target.setCustomValidity("Registreringsnummer skal ikke ha mellomrom");
                }
              }}
            />
            <Autocomplete
              value={newDog.breed}
              disableClearable
              options={dogbreeds.map((breed) => breed.navn)}
              onChange={(event, newValue) => {
                handleChange("breed", newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Rase" required />}
            />
            <KommuneSelect name="location" setKommune={() => void 0} value={member?.location} />
          </ColumnWrapper>

          <ColumnWrapper>
            <Stack gap={0}>
              <ButtonBase
                sx={{
                  height: "80%",
                  border: `2px dotted ${tan[50]}`,
                  maxWidth: "100%",
                  width: profilePicture ? "fit-content" : "100%",
                  borderRadius: "10px",
                }}
                onClick={() => setUploadImage(true)}
              >
                <Box position={"absolute"}>
                  {!profilePicture && <CiImageOn color={tan[300]} fontSize={"30px"} />}
                  <Typography variant="subtitle2">{profilePicture ? "Endre bilde" : "Last opp bilde"}</Typography>
                </Box>
                <Box height={"120px"} maxWidth={"100%"}>
                  <img
                    style={{ display: "flex", width: "100%", height: "120px", objectFit: "cover", borderRadius: "10px", opacity: 0.4 }}
                    alt=""
                    src={src}
                  />
                </Box>
              </ButtonBase>
              <CustomToggleButton
                leftValue="Tispe"
                rightValue="Hannhund"
                value={newDog.isMale ? "Hannhund" : "Tispe"}
                setSelectedResult={(e) => handleChange("isMale", e === "Hannhund")}
                label="Kjønn"
              />
            </Stack>
            <TextField
              sx={{ marginTop: "20px" }}
              value={newDog.fatherRegistryId ?? ""}
              onChange={(e) => handleChange("fatherRegistryId", e.target.value)}
              label="Far (Registreringsnummer)"
              required
              placeholder="NO22222/22"
              onInput={(e: FormEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;

                const reg = new RegExp(/^\S*$/);

                if (reg.test(target.value)) {
                  target.setCustomValidity("");
                } else {
                  target.setCustomValidity("Registreringsnummer skal ikke ha mellomrom");
                }
              }}
            />
            <TextField
              sx={{ marginTop: "20px" }}
              value={newDog.motherRegistryId ?? ""}
              onChange={(e) => handleChange("motherRegistryId", e.target.value)}
              label="Mor (Registreringsnummer)"
              required
              placeholder="NO33333/21"
              onInput={(e: FormEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;

                const reg = new RegExp(/^\S*$/);

                if (reg.test(target.value)) {
                  target.setCustomValidity("");
                } else {
                  target.setCustomValidity("Registreringsnummer skal ikke ha mellomrom");
                }
              }}
            />
          </ColumnWrapper>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} marginTop={"40px"}>
          <Button onClick={() => setOpen(false)} type="reset" variant="outlined" color="secondary">
            Avbryt
          </Button>
          <Button variant="contained" type="submit">
            Legg til hund
          </Button>
        </Box>
      </Box>{" "}
      <Uploader
        id="addDog"
        onUpload={(file: File, _ = false) => Promise.resolve(setProfileImage(file))}
        uploadImage={uploadImage}
        handleClose={() => setUploadImage(false)}
        setProfilePicture={true}
        closeAfterUpload={true}
      />
    </Box>
  );
};

export default AddDogRegistrationForm;

const AddButton = styled(ButtonBase)`
  width: 100%;
  height: 100px;
  border-radius: 20px;
`;
