"use client";

import { useUser } from "@clerk/nextjs";
import { Box, Container } from "@mui/material";
import { useRouter } from "next/navigation";
import { Dispatch, SetStateAction, useState } from "react";
import AddDogRegistrationForm from "../../../components/MyProfile/Modals/AddDog/AddDogForm";
import { orange } from "../../../components/Theme";
import { HandleAsyncFunctionWithToast } from "../../../components/Toasts";
import { useRegisterDogEndpoint } from "../../../components/generated/happydogsComponents";
import { RegisterDogEndpointCommand } from "../../../components/generated/happydogsSchemas";
import { useUploadDogimage } from "../../../components/use-upload-image";

const AddDog = ({ setOpen, redirect = true }: { setOpen: Dispatch<SetStateAction<boolean>>; redirect?: boolean }) => {
  const { user } = useUser();
  const router = useRouter();
  const memberId = (user?.publicMetadata?.member_id ?? null) as string;
  const [newDog, setNewDog] = useState<Partial<RegisterDogEndpointCommand>>({
    isMale: false,
    isAvailableForBreeding: false,
  });
  const registerDogMutation = useRegisterDogEndpoint();
  const [profilePicture, setProfileImage] = useState<File>();
  const uploadImageFn = useUploadDogimage();

  const registerDog = HandleAsyncFunctionWithToast(async (location: string) => {
    const { dogId, dogSlug } = await registerDogMutation.mutateAsync({
      body: {
        ...(newDog as RegisterDogEndpointCommand), //TODO: Validate all fields have been set
        memberId: memberId!,
        aboutMe: "",
        otherResults: [],
        healthResults: [],
        location,
      },
    });

    if (profilePicture) {
      await uploadImageFn(memberId, dogId, profilePicture, true);
    }

    setOpen(false);
    if (redirect) router.push(`/me/dogs/${dogSlug}`);
  });

  return (
    <Box width={"100%"} sx={{ backgroundColor: orange[15] }}>
      <Container maxWidth="lg">
        <AddDogRegistrationForm
          setNewDog={setNewDog}
          newDog={newDog}
          registerDog={registerDog}
          setProfileImage={setProfileImage}
          profilePicture={profilePicture!}
          setOpen={setOpen}
        />
      </Container>
    </Box>
  );
};

export default AddDog;
