import { Box, Checkbox, FormControlLabel, FormGroup, InputLabel } from "@mui/material";
import { BaseSyntheticEvent } from "react";
import { tan } from "../Theme";

interface Props {
  nkk: boolean;
  setNkk: (value: boolean) => unknown;
  breedClub: boolean;
  setBreedclub: (value: boolean) => unknown;
}

const BreedingGuildelines = ({ nkk, setNkk, breedClub, setBreedclub }: Props) => {
  return (
    <>
      <FormGroup>
        <InputLabel sx={{ color: tan[300], textAlign: "left" }}>Følger retningslinjer for avl *</InputLabel>
        <Box display={"flex"}>
          <FormControlLabel
            onChange={(e: BaseSyntheticEvent) => {
              setNkk(e.target.checked);
              if (!e.target.checked) setBreedclub(e.target.checked);
            }}
            control={<Checkbox checked={nkk} />}
            label="Norsk Kennel Klubb"
          />
          <FormControlLabel
            onChange={(e: BaseSyntheticEvent) => {
              if (e.target.checked) setNkk(e.target.checked);
              setBreedclub(e.target.checked);
            }}
            control={<Checkbox checked={breedClub} />}
            label="Raseklubb"
          />
          <FormControlLabel
            control={<Checkbox checked={!(nkk || breedClub)} />}
            onChange={(e: BaseSyntheticEvent) => {
              setNkk(!e.target.checked);
              setBreedclub(!e.target.checked);
            }}
            label="Ingen"
          />
        </Box>
      </FormGroup>
    </>
  );
};

export default BreedingGuildelines;
